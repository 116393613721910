import React from "react";

const Testimonial = () => {
    return (
        // dark:text-gray-300
        <div className="text-gray-600  mt-12" id="reviews">
            <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
                <div className="mb-10 space-y-4 px-6 md:px-0">
                    <h2 className="text-center text-2xl font-bold text-gray-800  md:text-4xl">
                        What our clients are saying
                    </h2>
                </div>

                <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-28">
                    <div className="p-8 border border-gray-100 rounded-3xl bg-white   shadow-2xl shadow-gray-600/10">
                        <div className="flex gap-4">
                            <img className="rounded-full" src="https://ik.imagekit.io/jkw7sshfg/web_assets/karen.jpeg" width="100" height="100" alt="user avatar" loading="lazy" />
                            <div>
                                <h6 className="text-lg font-medium text-gray-700 ">Karen Barongo</h6>
                                <p className="text-sm text-gray-500 ">Young Entrepreneur</p>
                            </div>
                        </div>
                        <p className="mt-8">I was struggling to find a suitable rental property due to upfront payment requirements. But thanks to Enyumba's rent financing service, I was able to move into my dream home without the financial burden. The process was smooth, and their flexible repayment options gave me peace of mind. I highly recommend Enyumba to anyone looking for a convenient and stress-free renting experience.
                        </p>
                    </div>

                    <div className="p-8 border border-gray-100 rounded-3xl bg-white   shadow-2xl shadow-gray-600/10">
                        <div className="flex gap-4">
                            <img className="rounded-full" src="https://ik.imagekit.io/ecjzuksxj/web_assets/lamu_geneviive.jpeg?updatedAt=1700897890909" width="100" height="100" alt="user avatar" loading="lazy" />
                            <div>
                                <h6 className="text-lg font-medium text-gray-700 ">Lamu Genevive</h6>
                                <p className="text-sm text-gray-500 ">Business lady</p>
                            </div>
                        </div>
                        <p className="mt-8">I am a business lady who was in search of a nice place I could turn into my office and when I discovered Enyumba rent financing, it provided the best options. Not only was the virtual tour affordable, but I could also pay for the room as I use it and at a low-interest rate!
                        </p>
                    </div>

                    <div className="p-8 border border-gray-100 rounded-3xl bg-white   shadow-2xl shadow-gray-600/10">
                        <div className="flex gap-4">
                            <img className="rounded-full" src="https://ik.imagekit.io/ecjzuksxj/web_assets/peace.jpg" width="100" height="100" alt="user avatar" loading="lazy" />
                            <div>
                                <h6 className="text-lg font-medium text-gray-700 ">Nivele Peace</h6>
                                <p className="text-sm text-gray-500 ">Software Engineer</p>
                            </div>
                        </div>
                        <p className="mt-8">Moving to town, I needed an affordable great place, but on the other hand a roommate who shares ma aspirations of music,  a person I could gel with. I happened to find Enyumba which not only got me an awesome place to live but they found the perfect roommate
                        </p>
                    </div>

                    {/* Repeat the above structure for other testimonials */}

                </div>
            </div>
        </div>
    );
};

export default Testimonial;

import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './App.css';

const Home = () => {
    const usps = [
        "Move into your dream home without upfront payments. Experience the convenience and flexibility of our rent now, pay later option. Start living in your dream space today!",
        "Discover hassle-free property hunting on our user-friendly app. Find apartments, commercial spaces, roommates, and hostels anytime, anywhere.",
        "Get compatible housemates who share your lifestyle and create unforgettable memories together.",
    ];

    const [currentUspIndex, setCurrentUspIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentUspIndex((prevIndex) => (prevIndex + 1) % usps.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="w-full h-screen overflow-hidden">
            <video loop autoPlay muted id="bg-video" className="w-full h-full object-cover">
                <source src="https://ik.imagekit.io/ecjzuksxj/property_assets/AdobeStock_413975716.mp4" type="video/mp4" />
            </video>
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
            <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2 text-center text-white">
                <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold font-poppins mb-4">
                    Rent Now, Pay Later
                </h1>

                <p
                    className="text-xl md:text-lg lg:text-xl font-poppins mb-8"
                    style={{
                        opacity: 1,
                        transition: 'opacity 45s ease-in-in', // Adjust the duration and easing as needed
                    }}
                >
                    {usps[currentUspIndex]}
                </p>
                <button className="bg-primary-blue text-white font-bold py-2 px-4 rounded-full hover:bg-blue-700">
                    <Link
                        activeClass="active"
                        to="features"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        style={{ cursor: 'pointer' }}
                    >
                        Learn More
                    </Link>
                </button>
            </div>
        </div>
    );
};

export default Home;

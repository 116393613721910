import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Outlet, useLocation } from 'react-router-dom';
import Home from './Home';
import Header from './Header';
import CTA from './CTA';
import Features from './Feature';
import MarketPlace from './MarketPlace';
import RentFinancing from './RentFinancing';
import Testimonial from './Testimonial';
import FAQ from './FAQ';
import IndexPage from './Footer';
import Roommate from './Roommate';
import PrivacyPolicy from './PrivacyPolicy';



const OtherPages = () => (
  <>
    <Header />
    <Home />
    <CTA />
    <Features />
    <MarketPlace />
    <RentFinancing />
    <Roommate />
    <Testimonial />
    <FAQ />
    <IndexPage />
  </>
);

const App = () => (
  <Router>
    <div className="App">

      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<OtherPages />} />
          <Route path="privacy" element={<PrivacyPolicy />} />

        </Route>
      </Routes>
    </div>
  </Router>
);

export default App;


import React, { useState, useEffect, useRef } from 'react';
import HeaderPrivacy from './PrivacyHeader';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (

        <div className="bg-white">
            <div><HeaderPrivacy /></div>

            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="max-w-4xl mt-40 mx-auto">
                    <h1 className="text-center text-6xl mb-8 font-bold text-gray-800 dark:text-white ">Privacy Policy</h1>

                    <p className=" text-center mb-32 ">
                        Last updated on November 2, 2023
                    </p>


                    <p className="mb-4 text-gray-500">
                        At Enyumba Inc, we understand that your privacy is important. We are committed to protecting your personal information and being transparent about how we collect, use, and store it. This Privacy Policy explains how we collect, use, and disclose information when you use our services.
                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Who We Are:</h2>
                    <p className="mb-4 text-gray-500">
                        We know that everyone’s home journey is different and that deciding on the right place to call home is an intimate, personal experience. Along with a wealth of information about homes, neighborhoods, market conditions, local real estate professionals, and financing options, we also use the information you give us to provide our services and help you find your next home.

                    </p>
                    <p className="mb-4 text-gray-500">
                        We carefully evaluate how we use data to ensure that we’re using your information to provide value. This page explains everything you need to know about your privacy at Enyumba Inc. If you have any questions we haven’t answered here, you can always contact us at privacy@enyumba.com.

                    </p>
                    <p className="mb-4 text-gray-500">
                        When we use the terms “Enyumba App or Enyumba”, “we”, “us”, or “our” in this Privacy Notice, we are referring to Enyumba, Inc. and its affiliated brands.

                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Personal Information We Collect:
                    </h2>

                    <p className="mb-4 text-gray-500">
                        We collect personal information when you use our services, such as your name, email address, phone number, and payment information. We may also collect information about your device, location, and browsing history.
                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">How We Use Your Personal Information:

                    </h2>
                    <p className="mb-4 text-gray-500">
                        We use your personal information to provide and improve our services, such as finding, renting, or listing a home, getting Rent Now & Pay Later, and connecting to a real estate pro. We may also use your information to communicate with you about our services, send you marketing communications, and personalize your experience.
                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Other Uses of Personal Data

                    </h2>
                    <p className="mb-4 text-gray-500">
                        In addition to the uses identified above, we use the personal data we collect to:
                    </p>

                    <ul className="list-disc pl-8 mb-8">

                        <li className="py-3 text-gray-500">Facilitate our day-to-day business operations, such as helping you find your next home, or connect with a real estate agent, lender, landlord, or other real estate professional;
                        </li>

                        <li className="py-3 text-gray-500">Create and maintain the services, develop new products and services, and improve existing products and services;
                        </li>

                        <li className="py-3 text-gray-500">Aggregate information we receive from you and third parties to help understand individual needs, customize our services and offerings, and provide everyone better service;
                        </li>

                        <li className="py-3 text-gray-500">Conduct research and analytics designed to understand our customer base and improve and grow our business, products, services, and customer experience;
                        </li>

                        <li className="py-3 text-gray-500">Communicate with you to provide technical or administrative support;
                        </li>

                        <li className="py-3 text-gray-500">Prevent, investigate, and defend against fraud or unlawful or criminal activity, access to, or use of personal data and our data system services;

                        </li>
                        <li className="py-3 text-gray-500">Enforce, investigate, and resolve disputes and security issues and to enforce our Terms of Service and any other contracts;
                        </li>

                        <li className="py-3 text-gray-500">Prevent, investigate, and defend against fraud or unlawful or criminal activity, access to, or use of personal data and our data system services;

                        </li>
                        <li className="py-3 text-gray-500">Comply with legal obligations and other governmental demands;
                        </li>

                        <li className="py-3 text-gray-500">Understand your preferences and interests to serve you on your home-finding journey better;

                        </li>
                        <li className="py-3 text-gray-500">Personalize your individual experience, including providing educational resources and pointing you to homes you might be interested in or offerings of ours that might help you find your next home; and

                        </li>
                        <li className="py-3 text-gray-500">For any other lawful, legitimate business purpose.
                        </li>
                    </ul>

                    <h2 className="text-xl font-bold mt-16 mb-4">How We Share Your Personal Information:
                    </h2>
                    <p className="mb-4 text-gray-500">
                        We may share your personal information with third-party service providers who help us provide our services, such as payment processors, marketing partners, and analytics providers. We may also share your information with law enforcement or regulatory agencies if required by law.
                    </p>

                    <p className="mb-4 text-gray-500">
                        We may disclose your personal data in the instances described below.  For further information on your choices regarding your information, see the “Choices About Your Data” section below.


                    </p>

                    <p className="mb-4 text-gray-500">
                        We disclose your personal data in the following ways:
                    </p>

                    <p className="mt-3 text-gray-500">
                        <span >
                            <strong className="font-semibold text-gray-900">Within Enyumba App: </strong>We are able to offer the products and services we make available because of the hard work of the entire Enyumba App team. Enyumba App entities disclose your personal data to other Enyumba App entities for purposes and use that are consistent with this Privacy Notice and applicable law. For example, one part of Enyumba Inc might share your personal data with another to ensure that everyone helping in your home journey is working together for you.
                        </span>
                    </p>

                    <p className="mt-3 text-gray-500">
                        <span >
                            <strong className="font-semibold text-gray-900">Business Partners: </strong>At your direction, we may share your personal data with our business partners in order to provide you with our products and services. For example, if you ask us to, we’ll share your contact information with a real estate agent or mortgage lender.
                        </span>
                    </p>
                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Marketing Partners: </strong>We coordinate and share your personal data with our marketing partners, including advertising networks, social networks, and marketing communication providers, in order to communicate with you about our products and services and market our products and services to you. We may also share aggregated demographic information with third parties interested in advertising on our online services to assist them in understanding the audience they would be reaching, but this information is not designed to identify any specific individual.

                        </span>
                    </p>

                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Service Providers: </strong>We share information with third party vendors and service providers that perform services for or on our behalf, which may include identifying and serving targeted advertisements, providing mailing or email services, tax and accounting services, customer service, product fulfillment, payments processing, photo sharing, data processing and enhancement services, fraud prevention, web hosting, analytic services, or other online functionality, subject to appropriate contractual terms protecting the confidentiality and use of such data. We never allow service providers to use your personal data for their own purposes.

                        </span>
                    </p>


                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Business Transaction or Reorganization: </strong>We may take part in or be involved with a corporate business transaction, such as a merger, acquisition, joint venture, or financing or sale of company assets. We may disclose your personal data to a third party during the negotiation of, in connection with, or as an asset in such a corporate business transaction. Your personal data may also be disclosed in the event of insolvency, bankruptcy, or receivership.

                        </span>
                    </p>


                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Legal Obligations and Rights: </strong>We may disclose your personal data to third parties, such as legal advisors and law enforcement:

                        </span>
                    </p>

                    <ul className="list-disc pl-8 mb-8">
                        <li className="py-3 text-gray-500">In connection with the establishment, exercise, or defense of legal claims;

                        </li>
                        <li className="py-3 text-gray-500">To comply with laws and regulations or to respond to lawful requests and legal processes;

                        </li>
                        <li className="py-3 text-gray-500">To protect our rights and property and the rights and property of our agents, customers, and others, including enforcing our agreements, policies, and terms of use;

                        </li>

                        <li className="py-3 text-gray-500">To detect, suppress, or prevent fraud;
                        </li>
                        <li className="py-3 text-gray-500">To reduce credit risk and collect debts owed to us;

                        </li>

                        <li className="py-3 text-gray-500">To protect the health and safety of us, our customers, or any person; or


                        </li>
                        <li className="py-3 text-gray-500">As otherwise required by applicable law.


                        </li>
                    </ul>

                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Otherwise, with Consent or At Your Direction: </strong>We may disclose your personal data to certain other third parties or publicly with your consent or direction. If you post a comment or review our website or a comment on our social media sites, the information you provide may be displayed publicly online for others to view.
                        </span>
                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Choices About Your Data

                    </h2>

                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Profile Access and Data Sharing: </strong>You may access and update your profile information, such as your user name, address, or billing information, and may change some of your data sharing preferences on your account page.


                        </span>
                    </p>

                    <p className="mb-4 text-gray-500">
                        During the KYC verification process, we collect and process face data, including 3D spatial orientation and facial expressions. This data is used solely for the purpose of authenticating user identities and is securely stored on our servers under the user's account information. We do not share this information with any third parties.
                    </p>

                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Location and Device Permissions: </strong>You may control location tracking by adjusting your location services options on the “Settings” app on your mobile device.  We may continue to approximate your location based on your IP address when you access the services through a computer or device. If you would like to update your device content access permissions, such as permissions to access your camera, you can do so in the “Settings” app on your mobile device.
                        </span>
                    </p>

                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Promotional Messages: </strong>You can stop receiving promotional email communications from us by following the “unsubscribe” instructions provided in such communications.  We make every effort to process all unsubscribe requests promptly. You may still receive service-related communications, including account verification, transactional communications, changes/updates to features of the services, and technical and security notices.
                        </span>
                    </p>

                    <p className="mt-3 text-gray-500">
                        <span>
                            <strong className=" font-semibold text-gray-900">Third-Party Tracking and Interest-Based Advertising: </strong>We participate in interest-based advertising and use third-party advertising companies to serve you targeted advertisements based on your browsing history. To learn more about our advertising practices and your choices relating to advertising, please see “Third-Party Tracking and Interest-Based Advertising.”
                        </span>
                    </p>


                    <h2 className="text-xl font-bold mt-16 mb-4">Third-Party Tracking and Interest-Based Advertising
                    </h2>
                    <p className="mb-4 text-gray-500">
                        We may participate in interest-based advertising and use third-party advertising companies to serve you targeted advertisements based on your browsing history. We may permit third-party online advertising networks, social media companies, and other third-party services to collect information about your use of our websites, including our mobile apps, over time so that they may play or display ads on our services, on other websites, apps, or services you may use, and on other devices, you may use. Typically, though not always, the information used for interest-based advertising is collected through tracking technologies, such as cookies, Flash objects, web beacons, embedded scripts, mobile SDKs, location-identifying technologies, and similar technology (collectively, “tracking technologies”), which recognize the device you are using and collect information, including clickstream information, browser type, time and date you visited the site, device ID or AdID, geolocation, and other information. We may share a common account identifier (such as an e-mail address or user ID) or hashed data with our third-party advertising partners to help identify you across devices. Our third-party partners and we may use this information to make the advertisements you see online more relevant to your interests and provide advertising-related services such as reporting, attribution, analytics, and market research.
                    </p>

                    <p className="mb-4 text-gray-500">
                        We, or our third-party partners, may link your various devices so that the content you see on one device can result in relevant advertising on another device. We may share a common account identifier (such as a hashed email address or user ID) or work with third-party partners who use tracking technologies or statistical modeling tools to determine if two or more devices are linked to a single user or household. We, and our partners, can use this cross-device linkage to serve interest-based advertising and other personalized content to you across your devices (including to improve your user experience), to perform analytics, and to measure the performance of our advertising campaigns
                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Your Choices
                    </h2>
                    <p className="mb-4 text-gray-500">
                        As noted above, depending on your browser or mobile device, you may be able to set your browser to delete or notify you of cookies and other tracking technology by actively managing the settings on your browser or mobile device. You may be able to limit interest-based advertising through the settings on your mobile device by selecting “limit ad tracking” (iOS) or “opt-out of interest-based ads” (Android).  Please note that opt-outs are browser-specific, so opting out on one browser will not affect a second browser or device. Moreover, if you buy a new device, use a different browser, or clear all your cookies, you will have to opt out all over again. To learn more about interest-based advertising and how you can opt out of some of this advertising, you may visit the Network Advertising Initiative’s online resources, at http://www.networkadvertising.org/choices, and/or the DAA’s resources at www.aboutads.info/choices. You may also be able to opt out of some – but not all – interest-based ads served by mobile ad networks by visiting http://youradchoices.com/appchoices and downloading the mobile AppChoices app. Note that some of these opt-outs may not be effective unless your browser is set to accept cookies. If you delete cookies, change your browser settings, switch browsers or computers, or use another operating system, you may need to opt out again.

                    </p>


                    <h2 className="text-xl font-bold mt-16 mb-4">Children’s Personal Data

                    </h2>
                    <p className="mb-4 text-gray-500">
                        Our websites and online services are not directed to, and we do not intend to knowingly collect or solicit personal data from, children under the age of 13. If you are under 13, please do not use our websites or online services or provide us with any personal data directly or by other means. If a child under the age of 13 has provided personal data to us, we encourage the child’s parent or guardian to contact us as described below to request that we remove the personal data from our systems. If we learn that any personal data we collect has been provided by a child under the age of 13, we will promptly delete that personal data.


                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Your Rights:

                    </h2>
                    <p className="mb-4 text-gray-500">
                        You have the right to access, correct, or delete your personal information. You can also opt out of marketing communicat

                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Data Security:


                    </h2>
                    <p className="mb-4 text-gray-500">
                        We take the security of your information seriously and use industry-standard measures to protect it.
                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Contact Us</h2>
                    <p className="mb-4 text-gray-500">
                        Ntinda Complex Bldg
                    </p>

                    <p className="mb-4 text-gray-500">
                        Block D, 1st Floor
                    </p>

                    <p className="mb-4 text-gray-500">
                        Ntinda – Kampala Uganda
                    </p>

                    <p className="mb-4 text-gray-500">
                        P.O Box 26230 Kampala, Uganda
                    </p>

                    <p className="mb-4 text-gray-500">
                        If you have any questions or concerns about our Privacy Policy, please contact us at
                        <a href="mailto:info@example.com" className="text-blue-500"> info@enyumba.com</a>.
                    </p>

                    <h2 className="text-xl font-bold mt-16 mb-4">Changes to Our Privacy Policy:

                    </h2>
                    <p className="mb-4 text-gray-500">
                        We may update our Privacy Policy from time to time. If we make any material changes, we will notify you by email or by posting a notice on our website.
                    </p>

                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>

    );
};

export default PrivacyPolicy;



import React from "react";
import { CurrencyDollarIcon, ArchiveBoxArrowDownIcon, PhotoIcon, SparklesIcon, UsersIcon, MegaphoneIcon } from '@heroicons/react/20/solid'

const Features = () => {
    return (
        <section id="features">
            <div class="container p-12 px-6 mx-auto bg-white">
                <div class="mb-16 text-center">

                    <p class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900  sm:text-4xl">
                        Why Choose Enyumba
                    </p>


                    <p class="text-xl mt-8 max-w-2xl mx-auto text-black">
                        We are the industry leader in the Prop-Tech space in East Africa. We are revolutionising living spaces, rent financing, roommate matching and property management.
                    </p>
                </div>
                <div class="flex flex-wrap my-12">
                    <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3">
                        <div class="flex items-center mb-6">
                            <MegaphoneIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                            <div class="ml-4 text-xl">
                                Market Place
                            </div>
                        </div>
                        <p class="leading-loose text-gray-500  text-md">
                            Hassle-free property hunting from apartments, commercial spaces, roommates, & hostels anytime, anywhere.
                        </p>
                    </div>
                    <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r">
                        <div class="flex items-center mb-6">
                            <CurrencyDollarIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                            <div class="ml-4 text-xl">
                                Rent Financing
                            </div>
                        </div>
                        <p class="leading-loose text-gray-500  text-md">
                            Enyumba understands that not everyone can pay the high rent upfront. Unlock Your Dream Home Now, Pay Later.
                        </p>
                    </div>
                    <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0">
                        <div class="flex items-center mb-6">
                            <PhotoIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                            <div class="ml-4 text-xl">
                                Virtual Tours
                            </div>
                        </div>
                        <p class="leading-loose text-gray-500  text-md">
                            Explore properties from anywhere with our immersive virtual tours that allow you to experience properties as if you were there.
                        </p>
                    </div>
                    <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0">
                        <div class="flex items-center mb-6">
                            <UsersIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                            <div class="ml-4 text-xl">
                                Roommate
                            </div>
                        </div>
                        <p class="leading-loose text-gray-500  text-md">
                            Get compatible housemates who share your lifestyle and create unforgettable memories together.
                        </p>
                    </div>
                    <div class="w-full p-8 border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0">
                        <div class="flex items-center mb-6">
                            <ArchiveBoxArrowDownIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                            <div class="ml-4 text-xl">
                                Property Holding
                            </div>
                        </div>
                        <p class="leading-loose text-gray-500  text-md">
                            Secure Your Future Home or workspace today with the property holding feature that ensures your dream property is reserved exclusively for you.
                        </p>
                    </div>
                    <div class="w-full p-8 md:w-1/2 lg:w-1/3">
                        <div class="flex items-center mb-6">
                            <SparklesIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                            <div class="ml-4 text-xl">
                                Rich Property Contextual Info
                            </div>
                        </div>
                        <p class="leading-loose text-gray-500  text-md">
                            Discover the True Essence of Each Property. No generic listings to enable you make informed decisions and find the perfect property.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;
import React from "react";

const CTA = () => {
    return (
        // <div class="bg-primary-blue dark:bg-orange-800 "></div>
        <section id="cta">
            <div class="bg-primary-blue ">
                <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                    <h2 class="text-3xl font-extrabold text-white dark:text-white sm:text-4xl">
                        <span class="block">
                            Our Offering
                        </span>

                    </h2>
                    <p class="text-xl mt-8 max-w-2xl mx-auto text-white">
                        We offer apartments, hostels, office and consumer store spaces and our roommate options, you are sure to find something just for you. Explore Now
                    </p>

                </div>
            </div>
        </section>

    );
}

export default CTA;
import { UsersIcon, ShieldCheckIcon, ChatBubbleLeftIcon } from '@heroicons/react/20/solid'

export default function Roommate() {
    return (
        <section id="roommate">
            <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                <div className="absolute inset-0 -z-10 overflow-hidden">
                    <svg
                        className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M100 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                    </svg>
                </div>
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="lg:max-w-lg">
                                <p className="text-base font-semibold leading-7 text-primary-blue">Discover</p>
                                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Roommates</h1>
                                <p className="mt-6 text-xl leading-8 text-gray-700">
                                    Discover your ideal housemate effortlessly. We match you with compatible individuals for a harmonious living experience. What are you waiting for? Start your roommate journey today!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                        <img
                            className="w-[48rem] max-w-none sm:w-[48rem]"
                            src="https://ik.imagekit.io/jkw7sshfg/web_assets/Group%2036248.png?updatedAt=1693445756374"
                            alt=""
                        />
                    </div>
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                            <p className="mt-6">
                                    How it Works
                                </p>

                                <ul role="list" className="mt-8 space-y-8 text-gray-600">
                                    <li className="flex gap-x-3">
                                        <UsersIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Select Roommate</strong>  List your property or find one that suits you. When listing a property, fill a 3-minute form about the property details and your personality information and when finding a roommate listing, select from our wide range of options.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <ShieldCheckIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Build Trust </strong> Build Trust Complete KYC verification such that we know who you are whether you are listing your house or looking for a roommate. Schedule property coverage appointment and process your payment to list. Our listings are verified for your security.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <ChatBubbleLeftIcon className="mt-1 h-5 w-5 flex-none text-primary-blue" aria-hidden="true" />
                                        <span>
                                            <strong className="font-semibold text-gray-900">Find Compatible Housemates</strong>  Set your preferences like price, location, and personal tastes. Get handpicked, curated suggestions of housemates tailored just for you.
                                        </span>
                                    </li>
                                </ul>

                                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Live Extraordinarily Together!</h2>
                                <p className="mt-6">
                                    Experience extraordinary living with our game-changing housemate feature. Live in luxury spaces, save costs, and share unforgettable experiences. Achieve your dreams, from jet-setting to securing your dream home. Don't miss out on the adventure of a lifetime. Step into a remarkable world today.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
